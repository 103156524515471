import { TKey } from 'locale';
import { ICylinder, CylinderState } from './types';
import TaskModel from './task';
import LoukEntryModel from './louk_entry';
import HardwareModel from './hardware';
import { CylinderStateInt, CylinderStatus } from 'domain/cylinder/types';

import { isCylinderAtRisk, getCylinderStatus } from 'domain/cylinder/cylinder_helper';

import { CUSTOM_THEME_COLOR } from 'common/constants';

export default class CylinderModel extends HardwareModel {
    public uuid: string;

    readonly unauthorizedKeys: Array<LoukEntryModel> = [];
    readonly tasks: Array<TaskModel> = [];
    readonly data: ICylinder;

    constructor(json: ICylinder) {
        super(json.name, json.marking, json.uuid, json.version);

        this.uuid = json.uuid;
        this.data = json;

        this.tasks = [];
        if (json && json.tasks) {
            for (const task of json.tasks) {
                this.tasks.push(new TaskModel(task));
            }
        }

        if (json && json.unauthorizedKeys) {
            for (const unauthorizedKey of json.unauthorizedKeys) {
                this.unauthorizedKeys.push(new LoukEntryModel(unauthorizedKey));
            }
        }
    }

    getColor() {
        if (isCylinderAtRisk(this)) {
            return CUSTOM_THEME_COLOR.red;
        }

        if (this.hasPendingJob()) {
            return CUSTOM_THEME_COLOR.orange;
        }

        if (this.isInstalled()) {
            return CUSTOM_THEME_COLOR.darkBlue;
        }

        return CUSTOM_THEME_COLOR.gray;
    }

    getStates() {
        const CYLINDER_STATUS_TRANSLATION_KEY_MAP = {
            [CylinderStatus.PENDING_ACCESS]: TKey.genericAccess,
            [CylinderStatus.PENDING_ACCESS_LOG]: TKey.genericAuditTrail
        };

        return getCylinderStatus(this)
            .filter(i => i)
            .map(j => CYLINDER_STATUS_TRANSLATION_KEY_MAP[j]);
    }

    stateIntRepresentation() {
        switch (this.data.state) {
            case CylinderState.INSTALLED:
                return CylinderStateInt.INSTALLED;
            case CylinderState.IN_STOCK:
                return CylinderStateInt.IN_STOCK;
            default:
                return CylinderStateInt.UNKNOWN;
        }
    }

    assignedTaskKey() {
        return this.data.assignedToKeyUuid;
    }

    isInStock() {
        return this.data.state === CylinderState.IN_STOCK;
    }

    isInstalled() {
        return this.data.state === CylinderState.INSTALLED;
    }

    setStateInstalled() {
        this.data.state = CylinderState.INSTALLED;
    }

    setStateInStock() {
        this.data.state = CylinderState.IN_STOCK;
    }

    hasPendingAccess() {
        return this.unauthorizedKeys.some((louk: LoukEntryModel) => louk.isPending());
    }

    hasRequestedAuditTrail() {
        return this.data.auditTrailRequested;
    }

    hasPendingJob() {
        return this.tasks.length > 0;
    }

    hasAssignedTaskKey() {
        return !!this.data.assignedToKeyUuid;
    }

    hasStartedTasks() {
        return this.tasks && this.tasks.some(task => task.isStarted());
    }

    hasStartedOrPendingUnassignedTask() {
        return this.tasks && this.tasks.some(task => task.isStarted() || task.isPendingUnassigned());
    }

    hasAssignedButNotStartedTasks() {
        return this.tasks && this.tasks.some(task => task.isAssignedButNotStarted());
    }

    hasPendingTasks() {
        return this.tasks && this.tasks.some(task => task.isAssignedButNotStarted() || task.isPendingUnassigned());
    }

    hasTasks() {
        return this.tasks && this.tasks.length > 0;
    }

    isKeyBlocked(keyUuid: string) {
        if (!this.unauthorizedKeys) {
            return false;
        }
        return this.unauthorizedKeys.some(loukEntry => loukEntry.keyUuid === keyUuid && loukEntry.isCurrent());
    }

    hasRemovedKeyInLouk(keyUuid: string) {
        if (!this.unauthorizedKeys) {
            return false;
        }
        return this.unauthorizedKeys.some(loukEntry => loukEntry.keyUuid === keyUuid && loukEntry.isRemoved());
    }

    hasAddedKeyInLouk(keyUuid: string) {
        if (!this.unauthorizedKeys) {
            return false;
        }
        return this.unauthorizedKeys.some(loukEntry => loukEntry.keyUuid === keyUuid && loukEntry.isAdded());
    }

    countStartedTasks() {
        return this.tasks ? this.tasks.filter(task => task.isStarted()).length : 0;
    }

    countPendingUnassignedTasks() {
        return this.tasks ? this.tasks.filter(task => task.isPendingUnassigned()).length : 0;
    }

    countAssignedButNotStartedTasks() {
        return this.tasks ? this.tasks.filter(task => task.isAssignedButNotStarted()).length : 0;
    }

    getLouks() {
        return this.data.louks || [];
    }

    toDataTransferObject(): ICylinder {
        return {
            uuid: this.uuid,
            name: this.name,
            state: this.data.state,
            marking: this.marking,
            version: this.data.version,
            versionPlug: this.data.versionPlug,
            cliqIdentity: this.data.cliqIdentity,
            auditTrailRequested: this.data.auditTrailRequested,
            assignedToKeyUuid: this.data.assignedToKeyUuid ? this.data.assignedToKeyUuid : null,
            tasks: this.tasks.map(task => task.toDataObject()),
            unauthorizedKeys: this.unauthorizedKeys.map(uKey => uKey.toDataObject())
        };
    }
}
